import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const MemberRepository = {
    
     /**
     * getMembers
     * 
     * @returns {Object} server response
     */ 
    async getMembers() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.MEMBERS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        //console.log('Memberrepository: getMembers Response', res)
        return res
    },

     /**
     * refresh
     * 
     * @param string postData
     * @returns {Object} server response
     */ 
    async refresh(postData : string) {
        const res: any = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.REFRESH, postData).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        //console.log('Memberrepository: getMembers Response', res)
        return res
    }    
}

export default MemberRepository;