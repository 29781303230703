import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ExportRepository = {

    /**
     * exportMember
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */  
    async exportMember(postData: string) {
        console.log('exportRepository:', postData);
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.EXPORT_MEMBERS, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('exportMemberResponse', res)
        return res
    },

}

export default ExportRepository;