import router from "./router";
import { UserController } from "@/controller";
//import { setLoading, unsetLoading } from "@/utils/loading";

const allowedRoutes = ['/', '/forgotpassword', '/resetpassword', '/login']

router.beforeEach(async (to, from, next) => {
    const allow = allowedRoutes.filter((route) => to.path == route || (route != '/' && to.path.indexOf(route) != -1) );
    //console.log('to, freeRouting', to.path, allow?.length);
    if(allow?.length || to?.meta?.noLoginCheck === true) {
        next()
      } else {
        //console.log('no free routing', to.path, allow?.length);
        //setLoading()
        if(!UserController.user) {
            await UserController.getUser();
        }
        //unsetLoading()
        next()
    }
})

