import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    redirect: "/",
    name: "Home",    
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        name: "home",
        meta: {
          title: "anybooka Home",
          noCache: true,
        }
      }
    ]
  },
  {
    path: "/login",
    redirect: "/",
    name: "Login",
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index.vue"),
        name: "login",
        meta: {
          title: "anybooka Login",
          noCache: true,
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },  
  {
    path: "/register",
    redirect: "/",
    name: "Register",
    children: [
      {
        path: "/register/:id?",
        component: () => import("@/views/register/index.vue"),
        name: "register",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }           
      }
    ]
  },  
  {
    path: "/confirmemail",
    redirect: "/",
    name: "ConfirmEmail",
    children: [
      {
        path: "/confirmemail/:id",
        component: () => import("@/views/confirmemail/index.vue"),
        name: "confirmemail",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },      
  {
    path: "/forgotpassword",
    redirect: "/",
    name: "ForgotPassword",
    children: [
      {
        path: "/forgotpassword",
        component: () => import("@/views/forgotpassword/index.vue"),
        name: "forgotpassword",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },  
  {
    path: "/resetpassword",
    redirect: "/",
    name: "ResetPassword",
    children: [
      {
        path: "/resetpassword/:hash",
        component: () => import("@/views/resetpassword/index.vue"),
        name: "resetpassword",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },    
  {
    path: "/admin/dashboard",
    redirect: "/",
    name: "Dashboard",
    children: [
      {
        path: "/admin/dashboard",
        component: () => import("@/views/admin/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          noLoginCheck: false
        }
      }
    ]
  },   
  {
    path: "/admin/member/import",
    redirect: "/",
    name: "Import",
    children: [
      {
        path: "/admin/member/import",
        component: () => import("@/views/admin/member/import/index.vue"),
        name: "import",
        meta: {
          noLoginCheck: false,
          menu: 'import',
          parentMenu: 'member'
        }
      }
    ]
  },  
  {
    path: "/admin/member/list",
    redirect: "/",
    name: "Memberlist",
    children: [
      {
        path: "/admin/member/list",
        component: () => import("@/views/admin/member/list/index.vue"),
        name: "memberlist",
        meta: {
          noLoginCheck: false,
          menu: 'memberlist',
          parentMenu: 'member'
        }
      }
    ]
  },
  {
    path: "/admin/member/export",
    redirect: "/",
    name: "Export",
    children: [
      {
        path: "/admin/member/export",
        component: () => import("@/views/admin/member/export/index.vue"),
        name: "export",
        meta: {
          noLoginCheck: false,
          menu: 'export',
          parentMenu: 'member'
        }
      }
    ]
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
