import { ExportRepository } from "../repository"
import { DataService } from '../service'
import { DateTimeUtils } from '@/utils'

const ExportController = {
    
    /**
    * @param {postData} any
    * @return {Object} request data
    */    
    async exportMembers(postData: any){
        const response : any = await ExportRepository.exportMember(JSON.stringify(postData))
        console.log('ExportController', response.data.csv.content)
        if (response?.data?.csv?.content) {
            const blob = new Blob([response?.data?.csv?.content], { type: "text/plain" })
            const link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download =  "wc_export_" + DateTimeUtils.formatDateForInput(new Date()) + ".csv"
            link.click()
        }
        return response
    }

}

export default ExportController;