
import store from '@/store/index';
import { defineComponent } from 'vue';
import { UiIconButton } from '@/ui';

export default defineComponent({
    name: "UiSearchbar",
    components: { UiIconButton },
    props:{
        storedValue:{
            type: String, 
            default: ''
        }
    },
    data(){
        return{
            store,
            input: "",
            icons: {
                search: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M11.09 13.6C14.7075 13.6 17.64 10.6675 17.64 7.05C17.64 3.43254 14.7075 0.5 11.09 0.5C7.47253 0.5 4.54001 3.43254 4.54001 7.05C4.54001 10.6675 7.47253 13.6 11.09 13.6Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M1.64001 16.5L6.47 11.67" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                deleteInput: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M8.82001 16.94C13.2383 16.94 16.82 13.3583 16.82 8.94C16.82 4.52172 13.2383 0.940002 8.82001 0.940002C4.40173 0.940002 0.820007 4.52172 0.820007 8.94C0.820007 13.3583 4.40173 16.94 8.82001 16.94Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6.64001 11.13L11 6.76001" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6.64001 6.76001L11 11.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            }
        }
    }, 
    watch:{
        storedValue(){
            this.input = this.storedValue;
        },
        input(){
            this.$emit('valueChange', this.input);
        }
    }, 
    mounted() {
        this.input = this.storedValue
    },
    methods: {
        resetSearch() {
            this.input = "";
        }
    }
})
