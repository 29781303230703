const PostData =  {

    build(data: Array<any>): string
    {
        let postData = '';
        for (const key in data) {
            /* if (data[key] == true) {
                data[key] = 1
            } else if (data[key] == false) {
                data[key] = 0
            } */
            postData += (postData ? `&` : '') + `${key}=` + encodeURIComponent(data[key])
        }
        return postData;
    }

};
export default PostData;