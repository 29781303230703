import { ImportRepository } from "../repository";
import { DataService } from '../service';

const ImportController = {
    
    async importMemberList(file : any){
        const postData : any = DataService.encodeFileToFormData(file)
        const response : any = await ImportRepository.importMemberList(postData)
        return response
    }

}

export default ImportController;