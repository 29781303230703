
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UiButton',
    props: {
        text: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        primary: {
            type: Boolean,
            default: false
        },
        raised: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        enableHover: {
            type: Boolean,
            default: true
        },
        waitForResponse:{
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideLabelOnMobile: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            loader: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" enable-background="new 0 0 38 38" xml:space="preserve"><g><g transform="translate(1 1)"><circle fill="none" stroke="#AEB6BA" stroke-width="2" stroke-opacity="0.5" cx="18" cy="18" r="18"/><path fill="none" stroke="#0038FF" stroke-width="2" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>',
        }
    },
    methods: {
        handleClicked(e : Event) {
            e.preventDefault();
            this.$emit('onClick');
        }
    }
})
