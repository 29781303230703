export default {
  "headline": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "pw_forgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "pw_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "pw_reset_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Änderung erfolgreich"])},
    "user_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich"])},
    "already_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast dies EMail-Adresse bereits bestätigt."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Moment bitte ..."])},
    "registration_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung abgeschlossen"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder importieren"])},
    "create_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitstag eintragen"])},
    "update_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitstag ändern"])},
    "work_overview_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsübersicht für "])},
    "delete_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeit löschen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "work_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder Arbeitsübersicht"])},
    "multiple_entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfacheinträge"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])}
  },
  "form": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "new_password_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "item_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Artikel"])}
  },
  "button": {
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "send_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link anfordern"])},
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück zum Login"])},
    "to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])},
    "reset_pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlegen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "save_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle speichern"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "add_multiple_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammeltage"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden eingeben"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage eingeben"])}
  },
  "label": {
    "not_registered_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch nicht registriert?"])},
    "register_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt registrieren"])},
    "pw_forgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "accept_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Datenschutzbestimmungen gelesen und akzeptiere diese."])},
    "read_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen lesen"])},
    "accept_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Allgemeinen Geschäftsbedinungen gelesen und akzeptiere diese."])},
    "read_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeinen Geschäftsbedinungen lesen"])},
    "item_number_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau Artikelnummer"])},
    "set_items_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel aktiv setzen"])},
    "openinghours_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Öffnungszeiten"])},
    "product_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktinformation"])},
    "product_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
    "all_seasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Saisonen"])},
    "all_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Standorte"])},
    "availability": {
      "option": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer verfügbar"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["während der Öffnungszeiten"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individuell"])}
      }
    },
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
    "scheduler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
    "export_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "export_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "sum_day_work_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "sum_hour_work_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "send_multiple_mails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mail an ", _interpolate(_named("recipients")), " schicken"])}
  },
  "error": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erforderlich"])},
    "wrong_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungültige EMail-Adresse"])},
    "too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu kurz"])},
    "dont_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
    "privace_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Datenschutzbestimmungen zustimmen"])},
    "terms_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Geschäftsbedinungen zustimmen"])},
    "no_valid_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Datum"])},
    "no_valid_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzer"])},
    "no_valid_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anzahl"])},
    "no_valid_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Arbeitseintrag"])}
  },
  "message": {
    "link_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dir einen Link zum Zurücksetzen deines Passwortes geschickt. Bitte kontrolliere auch deinen Spam / Junk Ordner."])},
    "pw_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Passwort erfolgreich zurückgesetzt!"])},
    "already_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist bereits registriert?"])},
    "submit_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätige nun die E-Mail, die wir dir geschickt haben, um dein Profil zu aktivieren."])},
    "submit_mail_tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleiner Tipp: Bitte überprüfe auch deinen Spam / Junk Ordner."])},
    "redirect_to_login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du wirst in ", _interpolate(_named("time")), " Sekunde/n automatisch zum Login weitergeleitet."])},
    "successfully_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Registrierung wurde erfolgreich abgeschlossen. Du kannst dich jetzt anmelden."])},
    "login_incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername od. Passwort falsch"])},
    "success": {
      "product_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Produkt wurde erfolgreich erstellt"])},
      "product_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde erfolgreich aktualisiert"])},
      "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde erfolgreich gelöscht"])},
      "items_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Artikel wurden erfolgreich angelegt"])},
      "item_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich gelöscht"])},
      "item_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich aktualisiert"])},
      "product_category_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die neue Produktkategorie wurde erfolgreich erstellt"])},
      "product_category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktkategorie wurde erfolgreich gelöscht"])},
      "product_category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktkategorie wurde erfolgreich aktualisiert"])},
      "location_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der neue Standort wurde erfolgreich erstellt"])},
      "location_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde erfolgreich aktualisiert"])},
      "location_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde erfolgreich gelöscht"])},
      "season_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die neue Saison wurde erfolgreich erstellt"])},
      "season_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Saison wurde erfolgreich aktualisiert"])},
      "season_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Saison wurde erfolgreich gelöscht"])},
      "openinghour_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten erfolgreich gelöscht"])},
      "availability_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit erfolgreich gelöscht"])},
      "work_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitseintrag erstellt"])},
      "work_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitseintrag aktualisiert"])},
      "work_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitseintrag gelöscht"])}
    },
    "confirm_delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt unwiederbringlich löschen?"])},
    "confirm_delete_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel unwiederbringlich löschen?"])},
    "confirm_delete_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorie unwiederbringlich löschen?"])},
    "confirm_delete_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitseintrag für diesen Tag löschen?"])},
    "confirm_delete_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison unwiederbringlich löschen?"])},
    "confirm_delete_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung unwiederbringlich löschen?"])},
    "overwrite_existing_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschreiben"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du besitzt nicht genügend Rechte für die Bearbeitung"])},
    "invalid_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ungültig"])},
    "select_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen!"])}
  },
  "route": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
    "memberlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "placeholder": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen / Filtern"])}
  },
  "nodata": {
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Mitglieder"])},
    "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitstage"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teams"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Arbeitsstage"])}
  },
  "model": {
    "member": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung / Team"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "daySum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitstage"])},
      "hourSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsstunden"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    },
    "import": {
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])}
    },
    "work": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
      "amount_options": {
        "half": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0,5"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,0"])},
        "oneandahalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,5"])}
      },
      "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])}
    }
  }
}