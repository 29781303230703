
import { defineComponent } from "vue"
import { UserController } from '@/controller/'
import { useEventBus } from '@vueuse/core'
import { sclappEventKey } from '@/bus/eventkey'

export default defineComponent({
    name: "ComSidebar",
    data(){
        return {
            activatedSub: '',
            icons: {
                dashboard: '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M6.49999 1.10999H0.679993V6.92998H6.49999V1.10999Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M15.22 1.10999H9.40002V6.92998H15.22V1.10999Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6.49999 9.83997H0.679993V15.66H6.49999V9.83997Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M15.22 9.83997H9.40002V15.66H15.22V9.83997Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                members: '<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.72999 5.03004C9.93397 5.03004 10.91 4.05403 10.91 2.85004C10.91 1.64606 9.93397 0.670044 8.72999 0.670044C7.52601 0.670044 6.54999 1.64606 6.54999 2.85004C6.54999 4.05403 7.52601 5.03004 8.72999 5.03004Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M3.63996 10.85C4.84394 10.85 5.81996 9.87396 5.81996 8.66998C5.81996 7.466 4.84394 6.48999 3.63996 6.48999C2.43598 6.48999 1.45996 7.466 1.45996 8.66998C1.45996 9.87396 2.43598 10.85 3.63996 10.85Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M13.82 10.85C15.0239 10.85 16 9.87396 16 8.66998C16 7.466 15.0239 6.48999 13.82 6.48999C12.616 6.48999 11.64 7.466 11.64 8.66998C11.64 9.87396 12.616 10.85 13.82 10.85Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M11.64 8.67003C11.64 6.26003 10.34 5.03003 8.73001 5.03003C7.12001 5.03003 5.82001 6.25003 5.82001 8.67003" stroke="#1B1B1B" stroke-miterlimit="10"/><path d="M6.54998 15.2201V14.4901C6.54998 12.0801 5.24998 10.8501 3.63998 10.8501C2.02998 10.8501 0.72998 12.0701 0.72998 14.4901V15.2201" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/><path d="M16.73 15.2201V14.4901C16.73 12.0801 15.43 10.8501 13.82 10.8501C12.21 10.8501 10.91 12.0701 10.91 14.4901V15.2201" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            },
            eventBus: useEventBus(sclappEventKey),
            isAdmin: false,
            isTeamManager: false
        }
    },
    computed: {
      activeRouteName() {
        return  this.$route?.meta?.menu || this.$route.name
      },
      activeParentRouteName() {
        return this.$route?.meta?.parentMenu;
      },
      appVersion() {
        return "V " + process.env.VUE_APP_VERSION;
      }
    },
    created() {
        this.eventBus.on((e) => {
            if (e === 'onUser') this.handleUserChanged()
            //if (e === 'onUserFetched') this.setUser();
        });         
    },
    methods: {
        handleRoutelinkClick() {
            this.$emit('routelinkClicked')
        },
        handleUserChanged() {
            this.isAdmin = UserController.isAdmin
            this.isTeamManager = UserController.isTeamManager
        },
        openSubNav(sub: string) {
            this.activatedSub = sub
        },
    }
})
