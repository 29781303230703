export const Api = Object.freeze({
    SCLAPP_VERSION: "v0.1",
    BACKEND_URL: process.env.VUE_APP_BACKEND,

        //login
    GET_PID: "/pid",
    CHECK_LOGIN: "/checklogin",
    LOGIN: "/login",
    LOGOUT: "/login",

        //user
    GET_USER: "/user",
    CREATE_USER: "/user/create",
    CONFIRM_EMAIL: "/user/confirmemail",
    FORGOT_PASSWORD: "/user/forgotpassword",
    RESET_PASSWORD: "/user/resetpassword",

        //import
    IMPORT_MEMBERS: "/member/import",

        //import
    MEMBERS: "/members",
    REFRESH: "/refresh",

        //work
    WORKS: "/works",
    CREATE_WORK: "/work/create",
    UPDATE_WORK: "/work/update",
    DELETE_WORK: "/work/delete",

        //export
    EXPORT_MEMBERS: "/member/export"    

})
