import { WorkRepository } from "../repository";
import { PostData } from "@/utils";

const WorkController = {

    /**
    * @return {Object} requested memberlist
    */
    /* async getWorks() {
        const response: any = await WorkRepository.getWorks(0);
        console.log('WorkController.getWorks: ', response);
        return response?.data?.works || [];
    }, */

    /**
    * @param {Object} member  
    * @param {string} from
    * @param {string} to
    * @return {Object} requested memberlist
    */
    async getWorksByMember(member: any, from: string, to:string) {
        const postData = {
            user: member?.uid,
            from: from,
            to: to
        }
        const response: any = await WorkRepository.getWorks(JSON.stringify(postData));
        const works = response?.data?.works || []
        member.sumDayWorks = this.getSumWorks(works, 0)
        member.sumHourWorks = this.getSumWorks(works, 1)
        //console.log('WorkController.getWorks: ', response);
        return this.sortWorks(works)
    },   

    getSumWorks(works: any, unit: number): number {
        let sum = 0
        works.forEach((work:any) => sum += (work.unit == unit ? work.amount : 0))
        return sum
    },

    sortWorks(works: any) {
        works.sort((a: any, b: any) => {
            return (a.day > b.day ? -1 : 1)
        });
        return works
    },

    /**
    * @param {workdata} any
    * @return {Object} request data
    */
    async create(workdata: any) {
        workdata = JSON.stringify(workdata)
        const response : any = await WorkRepository.create(workdata)
        //console.log('resonse.data, response.error', response.error);
        return response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        const response : any = await WorkRepository.update(postData)
        return response;
    },      

    /**
    * @param {any} id
    * @return {Object} request data
    */
    async delete(data:any) {
        const postData = PostData.build(data);
        const response : any = await WorkRepository.delete(postData)
        return response;
    }, 
}
export default WorkController;