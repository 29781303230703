import { UserRepository } from "../repository";
import router from "@/router";
import { useEventBus } from '@vueuse/core'
import { sclappEventKey } from '@/bus/eventkey'

const UserController = {
    user: null as any,
    router,
    eventBus: useEventBus(sclappEventKey),
    isAdmin: false,
    isTeamManager: false,
    adminGroupId: 2,
    teamManagerId: 3,
    //teamManagerId: 4,

    /**
    * @return {Object} requested userdata
    */
    async getUser() {
        const response: any = await UserRepository.getUser();
        if(response?.data?.user) {
            this.user = response.data.user
            this.setUserRights()
            this.eventBus.emit('onUser')
            return this.user;
        } else {
            this.router.push('/login')
        }
        //return response.data.user
    },
    
    /**
    * @param {username} string
    * @return {Object} request data
    */
    async sendPasswordLink(username: string) {
       const postData = {
        username: username
       }
       return await UserRepository.forgotPassword(JSON.stringify(postData));
    },

    /**
    * @param {postData} any
    * @return {Object} request data
    */
    async resetPassword(postData: any){
        //postData = DataService.buildPostData(postData,'', true);
        postData = JSON.stringify(postData)
        const response : any = await UserRepository.resetPassword(postData)
        return response
    },

    /**
    * @param {userdata} any
    * @return {Object} request data
    */
    async create(userdata: any) {
        userdata = JSON.stringify(userdata)
        const response : any = await UserRepository.create(userdata)
        return response;
    },

    /**
    * @param {postData} any
    * @return {Object} request data
    */
    async confirmMail(postData: any) {
        postData = JSON.stringify(postData)
        const response : any = await UserRepository.confirmMail(postData)
        return response;
    },  

    /**
    * @return void
    */
    setUserRights() {
        //console.log('setUserRights: ', this.user)
        for (const groupKey in this.user.feUser.usergroup) {
            if (this.user.feUser.usergroup[groupKey].uid == this.adminGroupId) this.isAdmin = true
            if (this.user.feUser.usergroup[groupKey].uid == this.teamManagerId) this.isTeamManager = true
        }
    }     


}
export default UserController;