import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ww-table" }
const _hoisted_2 = {
  key: 0,
  class: "ww-table-header"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["title", "onClick", "onMouseover"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = {
  key: 1,
  class: "td-actions"
}
const _hoisted_20 = {
  key: 0,
  class: "empty-state-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIconButton = _resolveComponent("UiIconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.icon
          }, null, 8, _hoisted_3),
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _createElementVNode("thead", {
        class: _normalizeClass({'head-rounded': !_ctx.title})
      }, [
        _createElementVNode("tr", null, [
          (_ctx.multipleSelect)
            ? (_openBlock(), _createElementBlock("th", _hoisted_4, [
                _createElementVNode("div", {
                  class: "ww-checkbox",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTableAllSelectionChange && _ctx.handleTableAllSelectionChange(...args)))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["ww-custom-checkbox", {'isChecked': _ctx.allItemsSelected}])
                  }, [
                    (_ctx.allItemsSelected)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: "svgicon",
                          innerHTML: _ctx.icons.check
                        }, null, 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
            return (_openBlock(), _createElementBlock("th", {
              class: _normalizeClass({ 'sorted': _ctx.currentSortField.property == field.property}),
              key: field,
              title: (_ctx.sortedAsc ? 'Ascending':'Descending') + ' sorted by '  + _ctx.currentSortField.property,
              onClick: ($event: any) => (_ctx.handleSortBy(field)),
              onMouseover: ($event: any) => (_ctx.startHover(field)),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.endHover()))
            }, [
              _createTextVNode(_toDisplayString(field.title) + " ", 1),
              (_ctx.currentSortField == field && _ctx.sortedAsc || _ctx.hover == field && _ctx.currentSortField != field)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createElementVNode("i", {
                      class: "svgicon active",
                      innerHTML: _ctx.icons.arrowsort
                    }, null, 8, _hoisted_8)
                  ]))
                : (_ctx.currentSortField == field && !_ctx.sortedAsc)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createElementVNode("i", {
                        class: "svgicon active",
                        style: {"transform":"rotate(180deg)"},
                        innerHTML: _ctx.icons.arrowsort
                      }, null, 8, _hoisted_10)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      _createElementVNode("i", {
                        class: "svgicon",
                        innerHTML: _ctx.icons.arrowsort
                      }, null, 8, _hoisted_12)
                    ]))
            ], 42, _hoisted_6))
          }), 128)),
          (_ctx.actions)
            ? (_openBlock(), _createElementBlock("th", _hoisted_13))
            : _createCommentVNode("", true)
        ])
      ], 2),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedList, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.uid,
            class: _normalizeClass({ 'selected' : _ctx.isItemSelected(item) })
          }, [
            (_ctx.multipleSelect)
              ? (_openBlock(), _createElementBlock("td", _hoisted_14, [
                  _createElementVNode("div", {
                    class: "ww-checkbox",
                    onClick: ($event: any) => (_ctx.handleTableSelectionChange(item))
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["ww-custom-checkbox", {'isChecked': item.isSelected}])
                    }, [
                      (item.isSelected)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "svgicon",
                            innerHTML: _ctx.icons.check
                          }, null, 8, _hoisted_16))
                        : _createCommentVNode("", true)
                    ], 2)
                  ], 8, _hoisted_15)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index,
                onClick: ($event: any) => (_ctx.handleActionClicked(field.clickAction, item))
              }, [
                (field.type == 'boolean')
                  ? (_openBlock(), _createBlock(_component_UiIconButton, {
                      key: 0,
                      class: _normalizeClass(["ww-button-toggle", { 'active': _ctx.checkTBField(index, item, field) == true }]),
                      icon: _ctx.icons.toggle,
                      onOnClick: ($event: any) => (_ctx.handleActionClicked(_ctx.actions.name, item))
                    }, null, 8, ["class", "icon", "onOnClick"]))
                  : (field.linkWrap)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.checkTBField(index, item, field)
                      }, null, 8, _hoisted_18))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: _normalizeClass({'clickable': field.clickAction})
                      }, _toDisplayString(_ctx.checkTBField(index, item, field)), 3))
              ], 8, _hoisted_17))
            }), 128)),
            (_ctx.actions)
              ? (_openBlock(), _createElementBlock("td", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                    return (_openBlock(), _createBlock(_component_UiIconButton, {
                      key: action.name,
                      icon: action.icon,
                      onOnClick: ($event: any) => (_ctx.handleActionClicked(action.name, item))
                    }, null, 8, ["icon", "onOnClick"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        (_ctx.src.length == 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_20, [
              _createElementVNode("td", null, _toDisplayString(_ctx.emptyStateText ? _ctx.emptyStateText : _ctx.$t('emptyState.default')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}