import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ImportRepository = {

    /**
     * loginUser
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async importMemberList(postData: any) {
        console.log('ImportRepository:', postData);
        const res = await axios.create({ 
                withCredentials: true,
            }).post(Api.BACKEND_URL + Api.IMPORT_MEMBERS, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('importMemberResponse', res)
        return res
    },

}

export default ImportRepository;