const AxiosErrorService = { 

  handleError(error: any) {
    console.log('AxiosErrorService error:', error.response);
    if (error.response) {
        return {
            'error': {
                'status': error.response.status,
                'data': error.response.data
            },
            'errorMessage': error.response.status + ' ' + error.response.data.error.message
        };
    } else {
        return {
            'error': {
                'status': '500',
                'data': 'http error'
            },
            'errorMessage': '500 http error'
        };
    }
  }

}

export default AxiosErrorService;