import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const UserRepository = {
    
     /**
     * getUser
     * 
     * @returns {Object} server response
     */ 
    async getUser() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_USER).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        //console.log('Userrepository: getUser Response', res)
        return res
    },

    /**
     * forgotpassword
     * 
     * @param {string} userData
     * 
     * @returns {Object} server response
     */  
    async forgotPassword(userData : string) {
        const res = await axios.post(Api.BACKEND_URL + Api.FORGOT_PASSWORD, userData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('forgotpassword', res)
        return res;
    },

     /**
     * resetPassword
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async resetPassword(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.RESET_PASSWORD, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('resetpassword', res)
        return res;
    },   

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_USER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createUser', res)
        return res;
    },   

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async confirmMail(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CONFIRM_EMAIL, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('confirmMail', res)
        return res;
    },      
}

export default UserRepository;