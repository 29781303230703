import { MemberRepository } from "../repository"
import { WorkController } from "."

const MemberController = {

    /**
    * @return {Object} requested memberlist
    */
    async getMembers() {
        const response: any = await MemberRepository.getMembers();
        //console.log('MemberController.getMembers: ', response);
        const members = response?.data?.members || []
        members.forEach((m:any) => {
            //m.works = []
                //[WS 2023-12-16] quick'n'dirty workaround, due to objects and worked with array so far
            const works = []
            for (const key in m.works) works.push(m.works[key])
            m.works = WorkController.sortWorks(works)
            m.sumDayWorks = WorkController.getSumWorks(works, 0)
            m.sumHourWorks = WorkController.getSumWorks(works, 1)
        })
        return members;
    },

    /**
    * @param number tstamp
    * @return {Object} requested memberlist
    */
    async refresh(tstamp: number) {
        tstamp = Math.floor(tstamp / 1000)
        const postData = JSON.stringify({'tstamp': tstamp})
        const response: any = await MemberRepository.refresh(postData);
        //console.log('MemberController.getMembers: ', response);
        const members = response?.data?.members || []
        members.forEach((m:any) => {
            //m.works = []
                //[WS 2023-12-16] quick'n'dirty workaround, due to objects and worked with array so far
            const works = []
            for (const key in m.works) works.push(m.works[key])
            m.works = WorkController.sortWorks(works)
            m.sumDayWorks = WorkController.getSumWorks(works, 0)
            m.sumHourWorks = WorkController.getSumWorks(works, 1)
        })
        return members;
    }  
}
export default MemberController;