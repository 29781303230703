import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "ww-button__icon"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "ww-button__label"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["ww-button", {'primary': _ctx.primary, 'secondary': !_ctx.primary, 'raised': _ctx.raised, 'disabled': _ctx.disabled, 'hide-label-on-mobile': _ctx.hideLabelOnMobile, 'has-icon': _ctx.icon, 'loading': _ctx.waitForResponse }]),
    disabled: _ctx.waitForResponse || _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args)), ["prevent"]))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.icon
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (_ctx.waitForResponse)
      ? (_openBlock(), _createElementBlock("i", {
          key: 2,
          class: "svgicon svgicon--loader",
          innerHTML: _ctx.loader
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}