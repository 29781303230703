import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "data-buttons"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-value", "onClick", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "ww-option-button__icon"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "ww-option-button__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-option-buttons", _ctx.alignmentAndSize]),
    id: _ctx.cId,
    "data-buttons": _ctx.options.length
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, idx) => {
      return (_openBlock(), _createElementBlock("button", {
        key: _ctx.cId + '_' +idx,
        class: _normalizeClass(["ww-custom-option-button", {'is-checked': _ctx.value == option.value, 'hide-label-on-mobile': _ctx.hideLabelOnMobile, 'has-icon': option.icon, 'disabled': _ctx.disabled }]),
        "data-value": option.value,
        onClick: _withModifiers(($event: any) => (_ctx.handleChange(option)), ["prevent"]),
        disabled: _ctx.disabled
      }, [
        (option.icon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createElementVNode("i", {
                class: "svgicon",
                innerHTML: option.icon
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        (option.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(option.label), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_3))
    }), 128))
  ], 10, _hoisted_1))
}