import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const WorkRepository = {

     /**
     * getWorks
     * 
     * @param {string} postData
     * @returns {Object} server response
     */ 
    async getWorks(postData: string) {
        const res: any = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.WORKS, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        //console.log('Workrepository: getWorks Response', res)
        return res
    },

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.CREATE_WORK, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        //console.log('createWork', res)
        return res;
    },   

     /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.UPDATE_WORK, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateWork', res)
        return res;
    },     

     /**
     * delete
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async delete(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.DELETE_WORK, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteWork', res)
        return res;
    },     
}

export default WorkRepository;