
  import { defineComponent } from 'vue';
  import { ComSidebar } from "./components";
  import { UiIconButton } from "@/ui";
  import { UserController, LoginController } from "@/controller";
  import { useEventBus } from '@vueuse/core'
  import { sclappEventKey } from '@/bus/eventkey'

  export default defineComponent({
    name: "AppLayout",
    components: { ComSidebar, UiIconButton },
    data() {
      return {
        showSidebarNav: false,
        icons: {
          arrowright: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="13px" viewBox="0 0 12 13" enable-background="new 0 0 12 13" xml:space="preserve"><g><line fill="none" stroke="#1B1B1B" stroke-linecap="square" stroke-miterlimit="10" x1="6" y1="6.6" x2="11" y2="6.6"/><line fill="none" stroke="#1B1B1B" stroke-miterlimit="10" x1="1" y1="6.6" x2="6" y2="6.6"/><line fill="none" stroke="#1B1B1B" stroke-linecap="square" stroke-miterlimit="10" x1="5" y1="10.6" x2="1" y2="6.6"/><line fill="none" stroke="#1B1B1B" stroke-linecap="square" stroke-miterlimit="10" x1="5" y1="2.6" x2="1" y2="6.6"/></g></svg>',
          burger: '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">  <path d="M1.29999 1.02002H15.85" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M1.29999 5.39001H15.85" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M1.29999 9.75H15.85" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
          logout: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">  <path d="M10.09 8.94H6.45" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M13.73 8.94H10.09" stroke="#1B1B1B" stroke-miterlimit="10"/>  <path d="M10.82 14.76V16.94H0.639999V0.940002H10.82V3.13" stroke="#1B1B1B" stroke-miterlimit="10"/>  <path d="M10.82 6.03003L13.73 8.94003" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M10.82 11.85L13.73 8.94" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
        },
        eventBus: useEventBus(sclappEventKey),
        user: null,
        userInitials: ''
      };
    },
    computed: {
      viewClass() {
        let str = '' as string;
        const routename = this.$route.name as string;
        str += 'view-' + routename
        str += this.$route.meta.showFixedHeader ? ' has-fixed-header' : '' 
        str += this.$route.meta.showFooter ? ' has-footer' : ''
        str += this.showSidebarNav ? ' sidebarnav-active ' : ''
        return str;
      },
      key() {
        return this.$route.fullPath;
      },
      routeName() {
        return ( this.$route.name ? this.$route.name as string : '')
      },   
      showSidebar() {
        //console.log('noSidebar', this.$route.meta.noSidebar);
        if (this.$route.meta && this.$route.meta.noSidebar) {
          return false
        } else {
          return true;
        }
        //return this.$route?.meta?.noSidebar || true;
      },
      showBackButton() {
        return this.$route?.meta?.hasBackButton
      },
      appVersion() {
        return "V" + process.env.VUE_APP_VERSION;
      }
    },
    watch: {
      $route(val) {
          this.showSidebarNav = false
        }
    },
    created() {
        this.eventBus.on((e) => {
            if (e === 'onUser') this.handleUserChanged()
            //if (e === 'onUserFetched') this.setUser();
        });         
    },    
    methods: {    
      handleGoBack() {
        this.$router.go(-1);
      },
      handleToggleNav() {
        this.showSidebarNav = !this.showSidebarNav
      },
      handleLogout() {
        const response = LoginController.logout()
        this.$router.push('/login')
      },
      handleUserChanged() {
        this.user = UserController.user
        this.userInitials = UserController?.user?.feUser?.firstName?.substr(0,1) + UserController?.user?.feUser?.lastName?.substr(0,1)
      },       

    },  
  })
  